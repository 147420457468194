// @/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: '/diary'
  },
  {
    path: '/diary',
    name: 'Diary',
    component: () => import('@/views/DiaryView.vue')
  },
  {
    path: '/photos',
    name: 'Photos',
    component: () => import('@/views/PhotosView.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('@/views/TagsView.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('@/views/MineView.vue')
  },
  {
    path: '/write',
    name: 'Write',
    component: () => import('@/views/WriteView.vue')
    // ToDo: 打开页面的动画效果
    // meta: { transition: 'slide-up', hideTabBar: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// 设置路由守卫, 检查登录状态
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
