// @/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    /** 主题色 */
    primaryColor: '',

    /** 菜单栏背景色 */
    navigationBarColor: '',

    /** 用户 Token */
    token: localStorage.getItem('token') || ''
  },
  getters: {
    /** 是否已经登录 */
    isAuthenticated: state => !!state.token
  },
  mutations: {
    /** 设置主题色 */
    setPrimaryColor(state, color) {
      state.primaryColor = color;
    },

    /** 设置菜单栏背景色 */
    setNavigationBarColor(state, color) {
      state.navigationBarColor = color;
    },

    /** 设置用户 Token */
    setToken(state, token) {
      state.token = token;
    },

    /** 移除用户 Token */
    removeToken(state) {
      state.token = '';
    }
  },
  actions: {
    /** 设置主题色 */
    setPrimaryColor({ commit }) {
      const color = getComputedStyle(document.documentElement)
        .getPropertyValue('--primary-color')
        .trim();
      commit('setPrimaryColor', color);
    },

    /** 设置菜单栏背景色 */
    setNavigationBarColor({ commit }) {
      const color = getComputedStyle(document.documentElement)
        .getPropertyValue('--navigation-bar-color')
        .trim();
      commit('setNavigationBarColor', color);
    }
  },
  modules: {}
});
