<!-- @/App.vue -->
<template>
  <router-view v-slot="{ Component, route }">
    <transition
      :name="
        typeof route.meta.transition === 'string'
          ? route.meta.transition
          : 'default'
      "
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>
