// @/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 导入特殊的全局样式
import '@/assets/styles/special.less';

// 导入 Element Plus 的组件库与图标库
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

// 初始化 Vue 应用
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  // 全局注册 Element Plus 的所有图标
  app.component(key, component);
}
app.use(store).use(router).use(ElementPlus).mount('#app');

// 初始化一些 JavaScript 全局变量
store.dispatch('setPrimaryColor');
store.dispatch('setNavigationBarColor');
